import { setTextCity, formatType } from "@/utils";
import { Api } from "../../api";

export const fetchSection = (
  Redis = false,
  host = "",
  isBaltex = false,
  reset = false,
) => {
  return async (dispatch, getState) => {
    const { section } = getState();
    const { filter } = getState();

    const response = await Api.server.getCatalog(
      {
        params: { ...section.params, isBaltex },
        filter: { ...filter.params },
      },
      reset,
    );

    dispatch({ type: "FETCH_SECTION", payload: response.products });
    dispatch({ type: "SET_COUNT", payload: response.count });
    if (response.models.marks) {
      dispatch({ type: "FETCH_MARKS", payload: response.models.marks });
    }

    if (response.models.models) {
      dispatch({ type: "FETCH_MODELS", payload: response.models.models });
    }

    if (response.models.types) {
      dispatch({ type: "FETCH_TYPES", payload: response.models.types });
    }

    const { meta } = response;
    // if (siteId !== 's1') {
    // meta = await Api.catalog.getMeta(cachedRedisData ? Redis : false, catalogData, siteId, reset)
    // }
    /* if (response.models?.types?.length > 0) {
            const kuzov = filter.params.kuzov?.[0];
            if (kuzov) {
                const kuzovName = response.models?.types?.find(type => type.CODE === kuzov)?.NAME
                if (kuzovName) {
                    Object.keys(meta).map(key => {
                        let conuter = 0;
                        while (meta[key].indexOf(`(${kuzovName}), (${kuzovName})`) > -1 && conuter < 10) {
                            conuter++;
                            meta[key] = meta[key].replaceAll(`(${kuzovName}), (${kuzovName})`, `(${kuzovName})`)
                        }
                        while (meta[key].indexOf(`(${kuzovName}) (${kuzovName})`) > -1 && conuter < 10) {
                            conuter++;
                            meta[key] = meta[key].replaceAll(`(${kuzovName}) (${kuzovName})`, `(${kuzovName})`)
                        }
                    })
                }
            }
        } */

    const domains = await Api.server.getDomains(Redis, reset);
    Object.keys(meta).map((key) => {
      meta[key] = setTextCity(host, domains, meta[key]);
      if (key !== "addDescription") {
        meta[key] = formatType(meta[key]);
      }
      meta[key] = meta[key]?.replace("#MIN_PRICE#", response.minPrice);
    });
    dispatch({ type: "SET_META", payload: meta });

    dispatch({ type: "SET_ID_NEED_FILTER", payload: true });
    if (typeof response.isFiltersValid !== "undefined") {
      dispatch({
        type: "SET_IS_VALID_FILTER",
        payload: response.isFiltersValid,
      });
    }
  };
};

export const setSectionId = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_SECTION_ID", payload: value });
  };
};

export const setPageNum = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_PAGE_NUM", payload: value });
  };
};

export const setPageSize = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_PAGE_SIZE", payload: value });
  };
};

export const setSortBy = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_SORT_BY", payload: value });
  };
};

export const setSortOrder = (value) => {
  return (dispatch) => {
    dispatch({ type: "SET_SORT_ORDER", payload: value });
  };
};

export const fetchAllReviewsAndQuestions = (host, ids, isReset = false) => {
  return async (dispatch) => {
    const response = await Api.server.getAllReviewsAndQuestions(
      host,
      ids,
      isReset,
    );
    dispatch({ type: "FETCH_ALL_REVIEWS_AND_QUESTIONS", payload: response });
  };
};
