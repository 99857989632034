import { Api } from "../../api";
import { cacheFunctionData } from "/utils";

export const getUser = (reset = false) => {
  return async (dispatch) => {
    const user = await cacheFunctionData(Api.user.getUser, "user", reset);
    dispatch({ type: "SET_USER", payload: user });
  };
};

export const logOut = () => {
  return async () => {
    await Api.user.logOut();
  };
};
export const getPersonalAutos = (reset = false) => {
  return async (dispatch) => {
    const autos = await cacheFunctionData(
      Api.user.getPersonalAutos,
      "personalAutos",
      reset,
    );
    dispatch({ type: "SET_AUTOS", payload: autos });
  };
};
