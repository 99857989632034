import styles from "./index.module.scss";
import Link from "next/link";
import Icon from "/components/common/icon";
import { useEffect, useState } from "react";
import { Api } from "/api";
import axios from "axios";
import clsx from "clsx";
import Loader from "/components/common/loader";

let source;
export default function Search({ isScrolled, activate }) {
  const [q, setQ] = useState("");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobileActive, setIsMobileActive] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isLoadEmpty, setIsLoadEmpty] = useState(false);

  useEffect(() => {
    if (activate) {
      setIsMobileActive(true);
      setIsSelected(true);
    }
  }, [activate]);

  useEffect(() => {
    const body = document.querySelector("body");
    if (isSelected) {
      body.classList.add("popup");
    } else {
      body.classList.remove("popup");
    }
  }, [isSelected]);

  useEffect(() => {
    const getData = setTimeout(async () => {
      setIsLoadEmpty(false);
      setProducts([]);
      if (q.length > 2) {
        source = axios.CancelToken.source();
        setIsLoading(true);
        const products = await Api.catalog.searchProducts(q, source.token);
        if (products) {
          setProducts(products);
        } else {
          setIsLoadEmpty(true);
        }
      } else {
        setProducts([]);
      }
      setIsLoading(false);
    }, 300);
    return () => {
      if (source) {
        source.cancel("test");
      }
      clearTimeout(getData);
    };
  }, [q]);

  return (
    <>
      <div
        className={clsx(
          styles.SearchWrapper,
          isMobileActive && styles.SearchWrapperActive,
        )}
      >
        {isSelected && (
          <div
            className={styles.Background}
            onClick={() => {
              setQ("");
              setIsSelected(false);
              setIsMobileActive(false);
            }}
          />
        )}
        <div className={clsx(isSelected && styles.SearchActiveBorder)}>
          <div
            className={clsx(styles.Search, isScrolled && styles.SearchScrolled)}
          >
            <input
              placeholder="Поиск по сайту"
              value={q}
              onClick={() => setIsSelected(true)}
              onChange={(event) => setQ(event.target.value)}
            />
            {!isSelected ? (
              <Icon id="search" stroke={"#9294A9"} strokeWidth={2} size={24} />
            ) : (
              <div
                className={styles.Close}
                onClick={() => {
                  setQ("");
                  setIsSelected(false);
                  setIsMobileActive(false);
                }}
              >
                <Icon id="close" fill={"#333"} size={24} />
              </div>
            )}
            {(products.length > 0 || isLoading || isLoadEmpty) && (
              <div className={styles.Results}>
                {isLoadEmpty && (
                  <div className={styles.LoadEmpty}>
                    По вашему запросу ничего не найдено :(
                  </div>
                )}
                {isLoading && <Loader />}
                {products.map((item) => (
                  <div className={styles.ItemWrapper} key={item.ID}>
                    <Link key={item.ID} href={item.DETAIL_PAGE_URL}>
                      <a
                        className={styles.Item}
                        onClick={() => {
                          setQ("");
                          setIsSelected(false);
                        }}
                      >
                        <div className={styles.Photo}>
                          <a className={styles.Name}>
                            <img src={item.PICTURES_PATH?.[0]} />
                          </a>
                        </div>
                        <div className={styles.Name}>
                          <div className={styles.Name}>{item.NAME}</div>
                          <div className={styles.Art}>
                            Артикул: {item.ARTICLE.VALUE}
                          </div>
                        </div>
                        {/* <div className={styles.Price}>
                                                    {item.CATALOG.MIN_PRICE} руб
                                                </div> */}
                      </a>
                    </Link>
                    {item.FILE && (
                      <a
                        href={item.FILE}
                        rel="noreferrer"
                        target="_blank"
                        className={styles.File}
                        onClick={(e) => {
                          console.log("test");
                          e.stopPropagation();
                        }}
                      >
                        <Icon id="pdf" width={16} height={16} />
                        <span>Инструкция по установке</span>
                      </a>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
