export default (axios, config) => ({
  async getCatalog(params, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getCatalog?data=${encodeURI(JSON.stringify(params))}&reset=${reset}`,
    );
    return data;
  },
  async getFilter(params, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getFilter?data=${encodeURI(JSON.stringify(params))}&reset=${reset}`,
    );
    return data;
  },
  async getServices(reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getServices?reset=${reset}`,
    );
    return data;
  },
  async getServicesInstall(code, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getServicesInstall?code=${code}&reset=${reset}`,
    );
    return data;
  },
  async getSections(reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getSections?reset=${reset}`,
    );
    return data;
  },
  async getDomains(reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getDomains?reset=${reset}`,
    );
    return data;
  },
  async getBrands(reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getBrands?reset=${reset}`,
    );
    return data;
  },
  async getProduct(code, host, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getProduct?code=${code}&host=${host}&reset=${reset}`,
    );
    return data;
  },
  async getProductReviews(productId, host, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getProductReviews?productId=${productId}&host=${host}&reset=${reset}`,
    );
    return data;
  },
  async getProductQuestions(productId, host, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getProductQuestions?productId=${productId}&host=${host}&reset=${reset}`,
    );
    return data;
  },
  async getCatalogBanners(reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getCatalogBanners?reset=${reset}`,
    );
    return data;
  },
  async getEdostMinDays(cityName, kladr) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getEdostMinDays?cityName=${cityName}&kladr=${kladr}`,
    );
    return data;
  },
  async getClientComments(page, marka, model, kuzov, host, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getClientComments?page=${page}&marka=${marka}&model=${model}&kuzov=${kuzov}&host=${host}&reset=${reset}`,
    );
    return data;
  },
  async getBaltexSections(reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getBaltexSections?reset=${reset}`,
    );
    return data;
  },
  async getPromoVideo(code, host, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getPromoVideo?reset=${reset}`,
    );
    return data;
  },
  async getAllReviewsAndQuestions(host, ids, reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getAllReviewsAndQuestions?host=${host}&ids=${ids.join(",")}&reset=${reset}`,
    );
    return data;
  },
  async addLogError(page, errorType) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/addLogError?page=${page}&errorType=${errorType}`,
    );
    return data;
  },
  async getMarks(isBaltex, mark = "", model = "", kuzov = "", reset = false) {
    const { data } = await axios.get(
      `${config.API_SERVER_URL}/catalogServer/getMarks?mark=${mark}&model=${model}&kuzov=${kuzov}&isBaltex=${isBaltex}&reset=${reset}`,
    );
    return data;
  },
  async postCalcEdost(params) {
    const { data } = await axios.post(
      `${config.API_SERVER_URL}/catalogServer/getCalcEdost`,
      params,
    );
    return data;
  },
});
