export default (axios: any, config: any) => ({
  async getBasket() {
    const { data } = await axios.post(
      `${config.API_URL}/basket/getBasket`,
      {},
      { withCredentials: true },
    );
    return data;
  },
  async addToBasket(id: string, price = false) {
    const { data } = await axios.post(
      `${config.API_URL}/basket/addToBasket?id=${id}&price=${price}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
  async removeFromBasket(id: string) {
    const { data } = await axios.post(
      `${config.API_URL}/basket/removeFromBasket?id=${id}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
  async changeBasketQty({ id, qty }: any) {
    const { data } = await axios.post(
      `${config.API_URL}/basket/changeBasketQty?id=${id}&qty=${qty}`,
      {},
      { withCredentials: true },
    );
    return data;
  },
});
