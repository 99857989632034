import { useEffect, useState } from "react";
import { Api } from "/api";
import {
  getDeliveryCityData,
  setLocation,
  fetchDomains,
} from "@/redux/action-creaters/location";
import { useDispatch, useSelector } from "react-redux";
import { showPopup } from "/redux/action-creaters/popup";
import { popupTypes } from "/redux/reducers/popupReducer";
import Icon from "/components/common/icon";
import { useDebounce } from "usehooks-ts";
import styles from "./index.module.scss";
import { setLocationLogic } from "../../../utils/location";

export default function Location() {
  const dispatch = useDispatch();

  const mainCitys = [
    "Москва",
    "Санкт-Петербург",
    "Астрахань",
    "Барнаул",
    "Владивосток",
    "Волгоград",
    "Воронеж",
    "Екатеринбург",
    "Ижевск",
    "Иркутск",
    "Казань",
    "Кемерово",
    "Краснодар",
    "Красноярск",
    "Липецк",
    "Махачкала",
    "Нижний Новгород",
    "Новокузнецк",
    "Новосибирск",
    "Омск",
    "Оренбург",
    "Пенза",
    "Пермь",
    "Ростов-на-Дону",
    "Рязань",
    "Самара",
    "Саратов",
    "Тольятти",
    "Томск",
    "Тюмень",
    "Ульяновск",
    "Уфа",
    "Хабаровск",
    "Чебоксары",
    "Челябинск",
    "Ярославль",
  ];

  const [cityList, setCityList] = useState([]);
  const [cityLoading, setCityLoading] = useState(false);
  const [q, setQ] = useState("");
  const debouncedQ = useDebounce(q, 500);

  const { domains } = useSelector((state) => state.location);

  useEffect(() => {
    if (Object.values(domains).length === 0) {
      dispatch(fetchDomains());
    }
  }, [domains]);

  useEffect(async () => {
    if (debouncedQ.length > 2) {
      setCityLoading(true);
      const { suggestions } = await Api.location.getCityList(debouncedQ);
      setCityList(suggestions);
      setCityLoading(false);
    }
  }, [debouncedQ]);

  const selectCity = async (cityName, cityData = {}) => {
    console.log("window", window);
    if (typeof window !== "object") {
      return;
    }
    await dispatch(getDeliveryCityData(cityData, true));
    await dispatch(setLocation(cityName));

    setLocationLogic(cityName, cityData, domains);
    dispatch(showPopup(popupTypes.none));
  };

  useEffect(() => {
    const popupElement = document.querySelector(`#cityForm`);
    if (popupElement) {
      const firstInput = popupElement.querySelector("input");
      if (firstInput) {
        firstInput.focus();
      }
    }
  }, []);

  return (
    <>
      <h2>Выбор города</h2>

      <div className={styles.Search} id="cityForm">
        <input
          placeholder="Поиск города"
          value={q}
          onChange={(event) => setQ(event.target.value)}
        />
        {cityLoading && (
          <span className={styles.Loader}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 50 50"
            >
              <path d="M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z">
                <animateTransform
                  attributeName="transform"
                  type="rotate"
                  from="0 25 25"
                  to="360 25 25"
                  dur="0.5s"
                  repeatCount="indefinite"
                />
              </path>
            </svg>
          </span>
        )}
        {cityList.length === 0 && (
          <Icon id="search" stroke={"#9294A9"} strokeWidth={2} size={24} />
        )}
        {cityList.length > 0 && (
          <>
            <div className={styles.Close} onClick={() => setQ("")}>
              <Icon id="close" size={24} />
            </div>
            <ul>
              {cityList.map((city) => (
                <li
                  key={city.ID}
                  onClick={() =>
                    selectCity(
                      `${city.data.settlement ?? city.data.city}`,
                      city.data,
                    )
                  }
                >
                  {city.value}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
      <div className={styles.Citys}>
        <div className={styles.Title}>Крупные города</div>
        <ul>
          {mainCitys.map((city) => (
            <li key={city} onClick={() => selectCity(city)}>
              {city}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}
