import React from "react";
import styles from "./index.module.scss";

export default function Balloon({ point }) {
  return (
    <div className={styles.BalloonPoint}>
      {point.ADR}
      <br />
      {point.WORK_TIME}
    </div>
  );
}
