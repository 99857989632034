import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchShops } from "@/redux/action-creaters/location";
import { useRouter } from "next/router";
import styles from "./index.module.scss";
import Delivery from "/components/pages/product/delivery";
import Props from "/components/common/product/props";
import Offer from "/components/common/product/offer";
import Top from "/components/pages/product/top";
import Bottom from "/components/pages/product/bottom";
import BuyWithThis from "../buyWithThis";
import Files from "../files";
import Services from "../services";
import Images from "../images";
import Share from "../share";
import Brand from "../brand";
import { formatType, getProductsPhotos } from "../../../../utils";
import Sert from "../sert";
import { fetchAllReviewsAndQuestions } from "../../../../redux/action-creaters/section";
import ProductPageWrapper from "../bodyWrapper";
import { fetchDeliveryInfo } from "../../../../redux/action-creaters/product";
import Manager from "../manager";
import Stick from "../stick";

export default function ProductBody({ type = "Каталог" }) {
  const { cityName } = useSelector((state) => state.location);
  const { sections } = useSelector((state) => state.sections);
  const { product } = useSelector((state) => state.product);
  const dispatch = useDispatch();

  useEffect(async () => {
    dispatch(fetchDeliveryInfo(product.ID));
  }, []);

  useEffect(() => {
    if (cityName) {
      dispatch(fetchShops());
    }
  }, [cityName]);

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    // eslint-disable-next-line no-undef
    dataLayer.push({
      ecommerce: {
        currencyCode: "RUB",
        detail: {
          products: [
            {
              id: product.ID,
              name: product.NAME,
              price: product.CATALOG?.MIN_PRICE,
              category: sections[product.IBLOCK_SECTION_ID].NAME,
              brand: product.BRAND?.name,
              list: type,
            },
          ],
        },
      },
    });
  }, []);

  const router = useRouter();
  useEffect(() => {
    dispatch(
      fetchAllReviewsAndQuestions(
        window.location.host.includes("localhost")
          ? "msk"
          : window.location.host.split(".")[0],
        [product.ID],
        Boolean(router.query.reset),
      ),
    );
  }, [product]);

  const photos = getProductsPhotos(product);

  return (
    <ProductPageWrapper
      head={
        <>
          <h1 itemProp="name">
            {formatType(product.NAME)}
            <span>Артикул: {product.ARTICLE?.VALUE}</span>
          </h1>
          <Top productId={product.ID} />
        </>
      }
      left={
        <div id="productImages" className={styles.Photos}>
          <Stick stick={product.STICK} />
          <Images
            images={photos}
            video={product.VIDEO}
            alt={`${product.NAME} ${product.ARTICLE?.VALUE} в ${cityName}`}
          />
        </div>
      }
      bottom={<Bottom />}
    >
      <>
        <div className={styles.MainInfo}>
          <div className={styles.Props}>
            <Manager productId={product.ID} />
            {product.PROPERTIES.length > 0 && (
              <div className={styles.PropsBlock}>
                <div className={styles.PropsTitle}>Характеристики</div>
                <Props
                  isGray={true}
                  props={product.PROPERTIES}
                  filterUrl={product.FILTER_URL}
                />
              </div>
            )}
            <div
              className={styles.Link}
              onClick={() => {
                const section = document.getElementById("description");
                section.click();
                section.scrollIntoView({ behavior: "smooth", block: "start" });
              }}
            >
              Перейти к описанию
            </div>
            <div className={styles.FilesAndBrands}>
              <div className={styles.Files}>
                <Files file={product.FILE} />
                <Sert sert={product.CERT} />
              </div>
              <Brand data={product.BRAND} />
            </div>
            <Share
              productName={product.NAME}
              productUrl={product.DETAIL_PAGE_URL}
              photo={`${photos?.[0] && `${photos[0]}/small.webp`}`}
            />
          </div>
          {product.CATALOG && (
            <div>
              <div className={styles.Buy}>
                <Offer isDetail={true} product={product} key={product.ID} />
              </div>
              {product.CATALOG.AVAILABLE && (
                <div className={styles.Delivery}>
                  <Delivery />
                </div>
              )}
            </div>
          )}
        </div>
        <Services
          sectionId={product.IBLOCK_SECTION_ID}
          marka={product.MARKA?.CODE_ALT}
        />
        <BuyWithThis ids={product.BUY_WITH_THIS} />
      </>
    </ProductPageWrapper>
  );
}
