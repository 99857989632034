// @ts-ignore
import Cookies from "cookies";
import { useSelector } from "react-redux";

import { Api } from "@/api";
import Redis from "@/lib/redis";
import { wrapper } from "@/redux";
import {
  fetchMainAdv,
  fetchMainNews,
  fetchMainNumbers,
  fetchMainNumbersBaltex,
  fetchMainPopular,
  fetchMainPortfolio,
  fetchMainProductsReviews,
  fetchMainReviews,
  fetchMainSlider,
  fetchQuestions,
  fetchVideos,
} from "@/redux/action-creaters/content";
import { fetchShops } from "@/redux/action-creaters/location";
import { setDescription, setTitle } from "@/redux/action-creaters/meta";
import { fetchTopMarks } from "@/redux/action-creaters/models";
import BaltexHome from "~/components/baltex/pages";
import LandingsHome from "~/components/landings/pages";
import LayoutWrapper from "~/components/layouts/layoutWrapper";
import FarkopHome from "~/components/pages";
import { getBaltexSections } from "~/redux/action-creaters/baltex";
import { SiteTypes } from "~/redux/reducers/paramsReducer";
import { checkIsBaltex, checkIsLanding } from "~/utils";
import pagesWrapper from "~/utils/pagesWrapper";

export default function HomePage({
  scripts,
  host,
  slider,
  models,
  adv,
  productions,
  news,
}: any) {
  const { siteType } = useSelector((state: any) => state.params);
  return (
    <LayoutWrapper scripts={scripts} host={host} isMain>
      {siteType === SiteTypes.Baltex && (
        <BaltexHome
          slider={slider}
          adv={adv}
          productions={productions}
          news={news}
        />
      )}
      {siteType === SiteTypes.Landing && (
        <LandingsHome slider={slider} models={models} adv={adv} />
      )}
      {siteType === SiteTypes.Farkop && <FarkopHome host={host} />}
    </LayoutWrapper>
  );
}

export const getServerSideProps = wrapper.getServerSideProps(
  function (store): any {
    return async ({ req, query, res }: any) => {
      const data: any = {};
      const reset = Boolean(query.reset);
      // req.headers.host = 'baltex.ru';
      const { host } = req.headers;
      if (checkIsBaltex(host)) {
        data.slider = await Api.baltex.getMainSlider(Redis, reset);

        await store.dispatch(getBaltexSections(reset));
        await store.dispatch(fetchTopMarks(Redis, reset));
        data.adv = await Api.baltex.getMainAdv(Redis, reset);
        data.productions = await Api.baltex.getMainProduction(Redis, reset);
        data.news = await Api.baltex.getMainNews(Redis, reset);

        await store.dispatch(fetchQuestions(Redis, reset));

        await store.dispatch(fetchMainNumbersBaltex(Redis, reset));
      } else if (checkIsLanding(host)) {
        data.slider = await Api.lendings.getMainSlider(Redis, host, reset);
        data.models = await Api.lendings.getModels(Redis, host, reset);
        data.adv = await Api.lendings.getMainAdv(Redis, reset);

        await store.dispatch(fetchQuestions(Redis, reset));
      } else {
        store.dispatch(
          setTitle(
            "Farkop.ru - фаркопы и дополнительное оборудование для автомобилей с установкой",
          ),
        );
        store.dispatch(
          setDescription(
            "В компании «Фаркоп.ру» вы можете купить и установить фаркоп в Москве, Санкт-Петербурге и Нижнем Новгороде, дополнительное оборудование и аксессуары для автомобилей. Производим отправку в регионы России.",
          ),
        );

        await store.dispatch(fetchMainSlider(Redis, reset));
        await store.dispatch(fetchTopMarks(Redis, reset));
        await store.dispatch(fetchMainPopular(Redis, reset));
        await store.dispatch(fetchMainAdv(Redis, reset));
        await store.dispatch(fetchMainNews(Redis, reset, req.headers.host));
        await store.dispatch(
          fetchMainProductsReviews(Redis, reset, req.headers.host),
        );
        await store.dispatch(
          fetchMainPortfolio(Redis, reset, req.headers.host),
        );

        await store.dispatch(fetchMainNumbers(Redis, reset));
      }
      await store.dispatch(fetchMainReviews(Redis, reset));
      await store.dispatch(fetchVideos(Redis, reset));
      await store.dispatch(fetchShops(Redis, reset));

      if (query.PHPSESSID) {
        const cookies = new Cookies(req, res);
        cookies.set("PHPSESSID", query.PHPSESSID, {
          httpOnly: true,
          sameSite: "lax",
        });
        return {
          redirect: {
            destination: "/",
            permanent: true,
          },
        };
      }

      return pagesWrapper(
        data,
        store,
        Redis,
        {
          req,
          query,
        },
        "/",
      );
    };
  },
);
